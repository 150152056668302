import Card from "@material-ui/core/Card";
import React from "react";
import "./MediumCardWithHover.scss";

const Mediumcard = (props) => {
  return (
    <Card
      className={
        (props.page === "product-scaling" || props.componentSpecific === "no")
          ? " mbl-view medium-card2 label appsgridshadow"
          :  "medium-card1 label appsgridshadow"
      }
    >
      <div className="card-content">
        <img
          className="medium-view-image"
          src={props.data.MediumviewImage}
          alt={props.data.MediumviewAlt}
        />
        <div className="card-overlay1">
          <div className="medium-title-and-content-area">
            <div className={props.data.OnlyHeading === "yes" ? "medium-title-only-heading" : "medium-view-title"}>
              {props.data.MediumviewHeading}
            </div>
            <div className="medium-view-content mini-view-content1">
              {props.data.MediumviewContent}
            </div>
          </div>
        </div>
        {/* <div className="medium-card-button">
          <Button
            icon={
              <img
                className="effect-btn-svgicon"
                src={RightArrow}
                alt="click"
              />
            }
            customClassName="effect-btn"
            color={props.data.MediumviewButtonColor}
            label={props.data.MediumviewButtonLabel}
            routepath={props.data.route}
          />
        </div> */}
      </div>
    </Card>
  );
};

export default Mediumcard;
