import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import React from "react";
import exploreSynergy from "../../assets/Images/HowWeDoIt/Explore_synergies_2x.png";
import IndividualBox from "../../components/IndividualBox";
import Mediumcard from "../../components/MediumCardWithHover";
import "./MediumViewWithHover.scss";

const Mediumview = (props) => {
  return (
    <div className="body-container">
      <h2 className="title-header">{props.MediumviewData.title}</h2>
      {props.MediumviewData.underline ? "" : ""}

      <Grid className="mt-50" container direction="row" spacing={3}>
        {props.MediumviewData.data.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={6}
            className="card crd-spc"
          >
            <Mediumcard componentSpecific={props.MediumviewData.componentSpecific} page="product-scaling" data={item} />
          </Grid>
        ))}

        {props.MediumviewData.individualBoxData && (
          <Grid item xs={12} sm={12} md={6} className="card mbl-card crd-spc">
            <IndividualBox data={props.MediumviewData.individualBoxData} />
          </Grid>
        )}
      </Grid>
      <Link target="_blank" className="services-link" to="/contact-us">
        <img
          className="mbl-banner-synergy"
          src={exploreSynergy}
          alt={exploreSynergy}
        />
      </Link>
    </div>
  );
};

export default Mediumview;
