import { Card, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import HoverCardWithBtnIcon4 from "../assets/Icons/CRM.svg";
import RightArrow from "../assets/Icons/go.svg";
import HoverCardWithBtnIcon5 from "../assets/Icons/LMS.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import hoverCardImg6 from "../assets/Images/HowWeDoIt//Proactive_Security_measures.svg";
import hoverCardImg5 from "../assets/Images/HowWeDoIt/Centralised_repository.svg";
import hoverCardImg2 from "../assets/Images/HowWeDoIt/Completely_Automated_SSDLC.svg";
import MiniImgBnrBgImg from "../assets/Images/HowWeDoIt/contactus_250px.png";
import hoverCardImg3 from "../assets/Images/HowWeDoIt/Cost_cutting_on_Maintenance_Updates.svg";
import MediumviewImage1 from "../assets/Images/HowWeDoIt/Dual-shore-model.png";
import ClickToViewCardImg5 from "../assets/Images/HowWeDoIt/Ethical-Hacker.png";
import HoverCardWithBtnIcon1 from "../assets/Images/HowWeDoIt/fixed-cost.svg";
import BannerBgImage from "../assets/Images/HowWeDoIt/how-we-do-it-banner.png";
import ClickToViewCardImg1 from "../assets/Images/HowWeDoIt/Innovation-Consultant.png";
import MediumviewImage3 from "../assets/Images/HowWeDoIt/Off-shore-model.png";
import MediumviewImage2 from "../assets/Images/HowWeDoIt/On-shore-model.png";
import ourTraits from "../assets/Images/HowWeDoIt/our_traits_2x.png";
import ClickToViewCardImg4 from "../assets/Images/HowWeDoIt/Quality-Assurance-Engineer.png";
import hoverCardImg4 from "../assets/Images/HowWeDoIt/Reduced_Implementation_Time.svg";
import HoverCardWithBtnIcon3 from "../assets/Images/HowWeDoIt/robust-hybrid.svg";
import clientMovieSliderImage from "../assets/Images/HowWeDoIt/sdfljdsf_2x.png";
import ClickToViewCardImg3 from "../assets/Images/HowWeDoIt/Software-Engineer.png";
import hoverCardImg1 from "../assets/Images/HowWeDoIt/Synchronised_Development_Operations.svg";
import HoverCardWithBtnIcon2 from "../assets/Images/HowWeDoIt/time-and-material.svg";
import ClickToViewCardImg2 from "../assets/Images/HowWeDoIt/UI-UX-Consultant.png";
import MiniImageBannerBgImg2 from "../assets/Images/HowWeDoIt/what-we-do-banner-image.png";
import MiniImageBannerBgImg from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import IconCardListIcon1 from "../assets/Images/IconCardList/Creative_Idea.svg";
import IconCardListIcon3 from "../assets/Images/IconCardList/Feedback_Audience.svg";
import IconCardListIcon6 from "../assets/Images/IconCardList/MRR.svg";
import IconCardListIcon4 from "../assets/Images/IconCardList/Presentation.svg";
import IconCardListIcon2 from "../assets/Images/IconCardList/Software_Companies.svg";
import IconCardListIcon5 from "../assets/Images/IconCardList/Work_Flow.svg";
import ogimage from "../assets/Images/ogImages/OG_How-we-di-it.png";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
//Movie Slider\
import MobileSmoothSlider from "../components/MobileSmoothSlider";
//PlainCard
import PlainCard from "../components/SixPlainCard";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
//Click To View Card
import ClickToViewCard from "../templates/ClickToViewCard";
//Icon Card List
import IconCardList from "../templates/IconCardList";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
//Info View
import Infoview from "../templates/InfoView";
//Medium View
import MediumviewWithHover from "../templates/MediumViewWithHover";
//Mini Image Banner
import MiniImageBanner from "../templates/MiniImageBanner";
//Mini Image Banner 1
import MiniImageBanner1 from "../templates/MiniImageBanner1";
//Mini view
import Miniview from "../templates/MiniView";
//Six Hover Cards
import ThreePlainCards from "../templates/ThreePlainCards";
//Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

const HowWedoIt = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [articleData, setArticleData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=${limit}&page=${page}&type=Article&component=mini_view`
    ).then((response) => {
      setArticleData(response.data);
      setArticleRawData(response);
    });
  }, [limit, page]);
  /**
   * Image Banner // HIDING T EMPORATILY
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    altImg: BannerBgImage,
    bnrImgTitle: "How We Do It",
    subtitleclassname: "",
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <>
        {" "}
        <span className="red-line3letter red-3letter-mbl">Dig</span>iryte
        Advantage
      </>
    ),
    tag: "h2",
    width: "93px",
    InfoviewContent: (
      <>
        India has been providing offshore agile team for businesses across the
        globe for more than 2 decades. This has skyrocketed the number of
        software engineers being produced by universities across and they are
        being rapidly recruited by not just local businesses but by global
        businesses as well.
        <br />
        <br />
        Some of the biggest companies in the world today have Indian’s in their
        top management, they add a lot of value to these companies and these
        companies have been able to find them by first working with them within
        India and then moving them to their head offices.
        <br />
        <br />
        The recent increase in the wealth of investments in India has ena- bled
        a lot of the local companies to build world class products catering to
        the global audience. The agile team working on these products are
        getting great exposure of building for the world.
        <h3 className="infoviewsubcontent">Why do we do this?</h3>
        At Digiryte, we strive to upgrade our skills with every project we
        undertake. We work on future technologies to future proof the business
        and also accelerate our growth. Our team is constantly researching new
        technologies and innovative methods to add value to our client’s
        business,
        <h3 className="infoviewsubcontent">Why it matters to you?</h3>
        We believe that we are more than just a software consultancy. We want to
        be your partners throughout your product journey. That's why we work
        closely with you to understand your vision and bring the best in tech to
        solve your business challenges.
      </>
    ),
  };
  /**
   * Click To View Card
   */
  const ClickToViewCardData = [
    {
      title: "Innovation Consultant",
      heading: (
        <>
          <span className="black-line3letter">Inn</span>ovation Consultant
        </>
      ),
      width: "50px",
      content: (
        <div>
          Innovation consultants have a strong business understanding and are
          highly creative thinkers. They can navigate through any industry and
          are highly capable of preparing a roadmap for your product that
          survives fast-paced business and changing consumer demands. They work
          closely with you to understand your vision, how you want business to
          work, and then develop innovative ideas that matter to both your
          customers and the boardroom.
          <br /> <br /> The innovation consultants are overall responsible for
          the success of the project and put in place a unique strategy to test
          the viability of the product on the market. They constantly push
          borders to ensure that your product stands the test of time and stays
          ahead of customers' expectations.
        </div>
      ),
      image: ClickToViewCardImg1,
    },
    {
      title: "UI/UX Consultant",
      heading: (
        <>
          <span className="black-line3letter">UI/</span>UX Consultant
        </>
      ),
      width: "50px",
      content:
        "The user interface is the window through which users understand your brand and experiences your product. A creative and powerful user interface can transform the way consumers engages with your product. Our UI/UX designers understand the depth of human behaviour to create a unique UI that has a high impact on user experience. Our UI/UX team conducts comprehensive and essential research prior to the design and prototyping process, ensuring that we deliver ROI on exceptional experiences. We are your co-creation partners from design to delivery.",
      image: ClickToViewCardImg2,
    },
    {
      title: "Software Engineer",
      heading: (
        <>
          <span className="black-line3letter">Sof</span>tware Engineer
        </>
      ),
      width: "50px",
      content:
        "Changes in technology and new areas of specialisation keep our software engineers moving at a fast pace. Our highly talented team of Software Engineers analyses the needs of the client, then designs, tests and develops software to meet those business requirements. We will also carefully analyse the needs of end-users and work closely with you from the early stages of software planning, MVP development, testing, training and support to meet them effectively.",
      image: ClickToViewCardImg3,
    },
    {
      title: "Quality Assurance Engineer",
      heading: (
        <>
          <span className="black-line3letter">Qua</span>lity Assurance Engineer
        </>
      ),
      width: "50px",
      content:
        "Our Quality Assurance specialists not only monitor the quality of the project but also monitor it’s health before it reaches the market. They work closely with the various teams to monitor the quality of the design, test the functionality, and manage the release process. They ensure that the product complies with the industry and government guidelines prior to release.",
      image: ClickToViewCardImg4,
    },
    {
      title: "Ethical Hacker",
      heading: (
        <>
          <span className="black-line3letter">Eth</span>ical Hacker
        </>
      ),
      width: "50px",
      content:
        "Successful testing does not always mean that the system is 100% safe and secure, but it should be able to thwart unskilled hackers and automated attacks. To do this successfully, our certified Ethical Hackers are constantly updated with new security software and hardware and the latest security threats. Our team will then move forward to create unique scripts that simulate network security breaches to test both current and future network additions. These vulnerabilities are then reported and analysed in order to provide a risk assessment to strengthen the defence of the products.",
      image: ClickToViewCardImg5,
    },
  ];

  /**
   * Medium View
   */

  const MediumviewData = {
    title: (
      <div className="ttc">
        <span className="red-line3letter red-3letter-mbl">Our</span> Engagement
        Models
      </div>
    ),
    componentSpecific: "yes",
    underline: true,
    individualBoxData: {
      IndividualBoxTitle: "Explore synergies",
      IndividualBoxBtnLabel: "GET IN TOUCH",
      IndividualBoxBtnVariant: "contained",
      IndividualBoxBtnColor: "primary",
      route: "/contact-us",
    },
    data: [
      {
        MediumviewImage: MediumviewImage2,
        MediumviewAlt: "On-Shore Model",
        MediumviewHeading: "On-Shore Model",
        MediumviewContent: (
          <>
            Our On-Shore team is located in Manchester, UK, and will work
            closely with you to help with your product requirements. It is
            important to have seamless communication and therefore crucial that
            project managers work during the same hours that are well aligned
            with your availability. Our On-Shore Model implements tracking and
            reporting of work performance in line with the timelines of our
            UK-based clients to create an efficient work ecosystem.
            <br />
            <br />
            <b>Best Suited For</b>
            <br />
            <br />
            - UK’s Public Sector Enterprises or Business rooted in the UK who
            strictly want to work with a UK based team <br />- Businesses that
            require first-hand information on work progress and wants to
            interact continuously with the on-site team to quickly modify
            existing technology
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage3,
        MediumviewAlt: "Off-Shore Model",
        MediumviewHeading: "Off-Shore Model",
        MediumviewContent: (
          <>
            Our offshore development center is based in India, and we ensure
            that all work meets the strict quality and safety standards set by
            the company. You can expect high quality work with our talented
            off-shore resources without being affected by time zone differences.
            Get access to the best technology and skilled personnel at an
            affordable cost.
            <br />
            You can be assured of the security of your data as it is stored in
            our UK servers. We use the latest technology tools for project
            management, reporting, and communication that ensure a smooth
            workflow for your project.
            <br /> <br />
            <b>Best Suited For</b>
            <br />
            <br />- Start-ups, Serial Innovators, or Ideapreneurs who have a
            limited budget but do not want to compromise on the quality of work.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage1,
        MediumviewAlt: "Dual-Shore Model",
        MediumviewHeading: "Dual-Shore Model",
        MediumviewContent: (
          <>
            Get the best of both worlds with our Dual-Shore Model. Get access to
            our team in the UK and India as per your project requirements.
            Adopting the Dual Shore Model comes down to matching the right
            people with the right tasks. It is very important to find a team
            that is well-aligned with the type of project requirements. With the
            Dual-Shore Model, we offer you a dynamic range of offshore and
            onshore service options that can scale and adapt to your core
            business needs.
            <br />
            <br />
            <b>Best Suited For</b>
            <br />
            <br />- Startups, Small and Medium-Sized Enterprises (SME’s), or
            Large Enterprises who want to scale-up with the high technical
            competence at an affordable rate of the offshore model as well as
            the benefits of in-house expertise of the onshore model.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
    ],
  };
  const InfoviewData1 = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter red-3letter-mbl">Our</span> App
        Development Includes
      </div>
    ),
    tag: "h2",
    width: "100px",
    InfoviewContent: <></>,
  };
  /**
   * Hover card button
   */

  const PlainCardData = [
    {
      HoverCardWithBtntitle: "Synchronised Development & Operations",
      HoverCardWithBtnsubtitle: "(Improves Agility)",
      HoverCardWithBtnIcon: hoverCardImg1,
      HoverCardWithBtnIconAlt: "Synchronised Development & Operations",
      HoverCardIconClass: "plain-card-button-main-img",
    },
    {
      HoverCardWithBtntitle: "Completely Automated SSDLC",
      HoverCardWithBtnIcon: hoverCardImg2,
      HoverCardWithBtnIconAlt: "Completely Automated SSDLC",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Secure Software Development Life-cyle)",
    },
    {
      HoverCardWithBtntitle: "Cost cutting on Maintenance & Updates",
      HoverCardWithBtnIcon: hoverCardImg3,
      HoverCardWithBtnIconAlt: "Cost cutting on Maintenance & Updates",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Cost cutting without Compromising Quality)",
    },
    {
      HoverCardWithBtntitle: "Reduced Implementation Time",
      HoverCardWithBtnIcon: hoverCardImg4,
      HoverCardWithBtnIconAlt: "Reduced Implementation Time",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(Months to Minutes)",
    },
    {
      HoverCardWithBtntitle: "Centralised repository",
      HoverCardWithBtnIcon: hoverCardImg5,
      HoverCardWithBtnIconAlt: "Centralised repository",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle:
        "(Version control system for Improved Collobration)",
    },
    {
      HoverCardWithBtntitle: "Proactive Security measures",
      HoverCardWithBtnIcon: hoverCardImg6,
      HoverCardWithBtnIconAlt: "Proactive Security measures",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: "(From App to Infrastructure)",
    },
  ];
  const ThreeHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle:
      "We'd love to help with your full product development",
    HoverCardWithBtntitle1: "Fixed Cost Model",
    HoverCardWithBtntitle2: "Time & Material Model",
    HoverCardWithBtntitle3: "Robust Hybrid Model",
    HoverCardWithBtntitle4:
      "Customer Relationship Management (CRM) Development",
    HoverCardWithBtntitle5: "Learning Management System (LMS) Development",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContent1:
      "For the Fixed Cost part of the SDLC we will charge you based on the agreed milestones.",
    HoverCardWithBtnContent2:
      "For the T & M part of the SDLC we will charge you based on the cost agreed per hour for each and every resource involved in this Project.",
    HoverCardWithBtnContent3:
      "In this model Define & Design Phase will be in T&M and Development & Testing in Fixed Cost.",
    HoverCardIconClass: "mb-10",
    HoverCardWithBtnContentClass: "btncontent",
    Extracardclassname: "pricing-card-title",
    HoverCardExtraiconclassname: "pricing-card-icon",
    HoverCardWithBtnIconAlt1: "Fixed Cost Model",
    HoverCardWithBtnIconAlt2: "Time & Material Model",
    HoverCardWithBtnIconAlt3: "Robust Hybrid Model",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    route: "/contact-us",
  };

  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg,
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    tooltip: "yes",
    tip: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    underlineClass: "black-line",
  };
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner2Data = {
    title: (
      <span className="whatwedo">
        <span className="black-line3letter">Wha</span>t we do
      </span>
    ),
    titleClass: "mini-image-banner-title-area title-header",
    image: MiniImageBannerBgImg2,
    altTxt: "What we do",
    tip: "TAKE A LOOK",
    tooltip: "yes",
    btnClassName: "what-we-do-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/what-we-do",
    underline: false,
    underlineClass: "black-line",
  };
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner3Data = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };
  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon1,
      altTxt: "Agile Methodology",
      heading: "Agile Methodology",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Time-zone collobration",
      heading: "Time-zone collobration",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Tailored product Development",
      heading: "Tailored product Development",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon4,
      altTxt: "Transparent Communication",
      heading: "Transparent Communication",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Direct Onboarding",
      heading: "Direct Onboarding",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Constant Testing",
      heading: "Constant Testing",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
  ];
  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: ourTraits,
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: clientMovieSliderImage,
  };
  return (
    <Layout
      seo={{
        title: "How we do it | Digiryte",
        ogtitle: "How we do it | Digiryte",
        ogimage: ogimage,
        description:
          "From new-age mobile experiences to transitioning day-to-day operations to web applications, we're doing everything at Digiryte.",
        ogurl: `${BASE_SITE_URL}/how-we-do-it`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-30 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon mt-20"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Home"
          // color="black"
          // style={{fontSize:'24px',fontWeight:'bold'}}
          // label="Home"
          // variant={props.variant}
          routepath="/"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-32px",
            marginLeft: "40px",
          }}
        >
          Home
        </Typography>
      </div>
      <div className="mt-30"></div>
      <Infoview InfoviewData={InfoviewData} />
      <div className="mt-70 body-container ttc">
        <h2 className="h2-header ttc">
          <span className="red-3letter-mbl">The</span> Ryte Team For <br />{" "}
          <span className="red-line3letter">You</span>r Requirements
        </h2>
      </div>
      <div className="mt-30 body-container">
        <ClickToViewCard data={ClickToViewCardData} />
      </div>
      <div className="mt-50">
        <MediumviewWithHover MediumviewData={MediumviewData} />
      </div>
      <div className="mt-70 body-container">
        <div className="mt-50"></div>
      </div>
      <div className="mt-20">
        <MiniImageBanner1 id={1} MiniImageBanner1Data={MiniImageBanner1Data} />
      </div>
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData1} />
      </div>
      <div className="body-container">
        <Grid
          className="mt-50  hide-in-mobile"
          container
          direction="row"
          spacing={3}
        >
          {PlainCardData.map((item, index) => (
            <Grid className="appsgrid" key={index} item md={4}>
              <Card className="appsgridshadow">
                <PlainCard
                  HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider reduceheight="no" movieSlider={movieSlider} />
      </Grid>
      <div className="mt-70">
        <MiniImageBanner1
          id="two"
          MiniImageBanner1Data={MiniImageBanner2Data}
        />
      </div>
      <div className="body-container mt-70">
        <h2 className="ourtraits-title-header ttc">
          <span className="red-line3letter red-3letter-mbl">Our</span> Traits
        </h2>
        <div className="mt-50 mbl-hide">
          <IconCardList IconCardListData={IconCardListData} />
        </div>

        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
      </div>
      {/* <span className="hide-in-mobile"> */}
      <div className="mt-70"></div>
      {/* </span> */}
      <div className="body-container mt-20">
        <h2 className="title-header ttc">
          <span className="red-line3letter red-3letter-mbl">Pri</span>cing
          Models
        </h2>
      </div>
      <div className="mt-50">
        <ThreePlainCards ThreeHoverCardData={ThreeHoverCardData} />
      </div>
      <div className="mt-50">
        <MiniImageBanner miniImageBannerData={MiniImageBanner3Data} />
      </div>
      {articleData && articleData.length > 0 && (
        <React.Fragment>
          {" "}
          <div className="body-container mt-70">
            <h3 className="title-header recommended ttc">
              <span className="red-line3letter red-3letter-mbl">Rec</span>
              ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview miniviewData={articleData} />
          </div>
        </React.Fragment>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page + 1);
              setLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default HowWedoIt;
