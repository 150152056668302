import { Link } from "gatsby";
import React from "react";
import Button from "../../components/Button";
import "./IndividualBox.scss";

const IndividualBox = (props) => {
  return (
    <Link
      style={{ textDecoration: "none", color: "black" }}
      to={props.data.route}
    >
      <div className="individual-box">
        <div>
          <div className="individual-box-title">
            {props.data.IndividualBoxTitle}
          </div>
          <div className="individual-box-sub-title">
            {props.data.IndividualBoxSubTitle}
          </div>
          <div className="individual-box-button">
            <Button
              customClassName="individual-box-btn"
              variant={props.data.IndividualBoxBtnVariant}
              color={props.data.IndividualBoxBtnColor}
              label={props.data.IndividualBoxBtnLabel}
              routepath={props.data.route}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default IndividualBox;
